<template>
  <div class="login__container">
    <img
      class="background-img"
      src="../../assets/images/wave.png"
      alt="wave"
    >
    <div class="login__container-content">
      <img
        src="../../assets/images/booking.svg"
        alt="vehicle"
        class="vehicle"
      >

      <div class="content--form-container">
        <i class="fas fa-user-circle login-icon" />
        <h1 class="title">
          Service Driver and Vehicle Reservation System
        </h1>
        <ValidationObserver
          ref="form"
          v-slot="{ handleSubmit }"
        >
          <form
            class="mt-2 mb-4"
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <ValidationProvider
              #default="{ errors }"
              vid="email"
              name="email"
              rules="required|email"
            >
              <b-form-group>
                <label
                  for="emailInput"
                  class="form_label"
                >Email</label>
                <input
                  id="emailInput"
                  v-model="credentials.email"
                  type="email"
                  :class="
                    'form-control mb-0' +
                      (errors.length > 0 ? ' is-invalid' : '')
                  "
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              #default="{ errors }"
              vid="password"
              name="password"
              rules="required"
            >
              <b-form-group>
                <label
                  for="passwordInput"
                  class="form_label"
                >Password</label>
                <!-- <router-link
              to="/auth/password-reset1"
              class="float-right"
            >
              Forgot password?
            </router-link> -->
                <input
                  id="passwordInput"
                  v-model="credentials.password"
                  type="password"
                  :class="
                    'form-control mb-0' +
                      (errors.length > 0 ? ' is-invalid' : '')
                  "
                  placeholder="Enter Password"
                  required
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <div class="d-inline-block w-100">
              <!-- <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">Remember Me</label>
        </div> -->
              <b-button
                type="submit"
                variant="success"
                block
                :disabled="isBusy"
                class="mb-1"
              >
                Sign in
              </b-button>

              <b-button
                type="button"
                variant="secondary"
                block
                :disabled="isBusy"
                @click="useAuthProvider('oauth2', Oauth2)"
              >
                Sign in with Aljay SSO
              </b-button>
            </div>
            <!-- <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Don't have an account?
            <router-link
              to="/sign-up"
              class="iq-waves-effect pr-4"
            >
              Sign up
            </router-link>
          </span>
        </div> -->
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedAuthService, SharedSSOService } from '@/services'
import { Oauth2 } from 'universal-social-auth'

export default {
  name: 'SharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  data: () => ({
    isBusy: false,
    credentials: {
      email: '',
      password: ''
    },
    Oauth2
  }),

  mounted () {
    core.index()
  },

  methods: {
    useAuthProvider (provider, proData) {
      this.$Oauth
        .authenticate(provider, proData)
        .then(data => {
          this.useSocialLogin(data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async useSocialLogin (code) {
      await SharedSSOService.post(code)
        .then(response => {
          this.$store.dispatch('auth/saveToken', response.data.token)

          SharedAuthService.FetchUser()
            .then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)

              let routeName = 'dashboard'

              const user = this.$store.getters['auth/user']

              if (user) {
                routeName = `${user.role}.dashboard`
              }

              this.$router.replace({ name: routeName })
            })
            .catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })
        })
        .catch(err => {
          console.log({ err: err })
        })
    },

    async onSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.isBusy = true
          SharedAuthService.SignIn(this.credentials)
            .then(response => {
              this.$store.dispatch('auth/saveToken', response.data.token)

              SharedAuthService.FetchUser()
                .then(({ data }) => {
                  this.$store.dispatch('auth/fetchUserSuccess', data)

                  let routeName = 'dashboard'

                  const user = this.$store.getters['auth/user']

                  if (user) {
                    routeName = `${user.role}.dashboard`
                  }

                  this.$router.replace({ name: routeName })
                })
                .catch(() => {
                  this.$store.dispatch('auth/fetchUserFailure')
                })

              this.isBusy = false
            })
            .catch(error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.form.setErrors(error.message)
                }
              }
              this.isBusy = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss"  scoped>
.login__container {
  width: 100%;
  height: 100vh;
  overflow: visible;
  display: grid;
  align-items: center;

  .background-img {
    position: fixed;
    height: 100vh;
    width: 50%;
    bottom: 0;
    left: 0;
    z-index: -100;
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;

    .vehicle {
      display: flex;
      align-self: center;
      justify-self: center;
      width: 75%;
      height: 300px;
    }
  }
  .content--form-container {
    padding: 2.5rem;
    width: 100%;
    margin: auto;
    .login-icon {
      width: inherit;
      font-size: 3rem;
      text-align: center;
      color: #00cba9;
    }
    .title {
      font-size: 1.3rem;
      padding: 0;
      margin: 0;
      text-align: center;
      color: #00cba9;
    }

    .subtitle {
      font-size: 1.2rem;
      text-align: center;
      color: #00cba9;
    }
  }
}

@media screen and (max-width: 567px) {
  .login__container {
    .background-img {
      width: 100%;
    }

    .login__container-content {
      .vehicle {
        display: none;
      }

      .content--form-container {
        grid-column: 1 / span 2;

        .login-icon {
          font-size: 3rem;
        }
        .title {
          font-size: 1.3rem;
        }

        .subtitle {
          font-size: 1rem;
        }
        .form_label {
          color: #eee;
        }
      }
    }
  }
}
@media screen and (min-width: 568px) and (max-width: 767px) {
  .login__container {
    .background-img {
      width: 100%;
    }

    .login__container-content {
      .vehicle {
        display: none;
      }

      .content--form-container {
        grid-column: 1 / span 2;
        padding: 2rem 4rem;
        width: 100%;
        margin: auto;

        .login-icon {
          font-size: 3rem;
        }
        .title {
          font-size: 1.2rem;
        }

        .subtitle {
          font-size: 1rem;
        }
        .form_label {
          color: #eee;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .login__container {
    .background-img {
      width: 100%;
    }
    .login__container-content {
      .vehicle {
        display: none;
      }

      .content--form-container {
        grid-column: 1 / span 2;
        padding: 2rem 5rem;
        width: 100%;
        margin: auto;

        .login-icon {
          font-size: 3rem;
        }
        .title {
          font-size: 1.5rem;
        }

        .subtitle {
          font-size: 1.3rem;
        }
        .form_label {
          color: #eee;
        }
      }
    }
  }
}
</style>
