var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login__container"},[_c('img',{staticClass:"background-img",attrs:{"src":require("../../assets/images/wave.png"),"alt":"wave"}}),_c('div',{staticClass:"login__container-content"},[_c('img',{staticClass:"vehicle",attrs:{"src":require("../../assets/images/booking.svg"),"alt":"vehicle"}}),_c('div',{staticClass:"content--form-container"},[_c('i',{staticClass:"fas fa-user-circle login-icon"}),_c('h1',{staticClass:"title"},[_vm._v(" Service Driver and Vehicle Reservation System ")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt-2 mb-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"vid":"email","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"form_label",attrs:{"for":"emailInput"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials.email),expression:"credentials.email"}],class:'form-control mb-0' +
                    (errors.length > 0 ? ' is-invalid' : ''),attrs:{"id":"emailInput","type":"email","aria-describedby":"emailHelp","placeholder":"Enter Email","disabled":_vm.isBusy,"autocomplete":"off"},domProps:{"value":(_vm.credentials.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"form_label",attrs:{"for":"passwordInput"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials.password),expression:"credentials.password"}],class:'form-control mb-0' +
                    (errors.length > 0 ? ' is-invalid' : ''),attrs:{"id":"passwordInput","type":"password","placeholder":"Enter Password","required":"","disabled":_vm.isBusy,"autocomplete":"off"},domProps:{"value":(_vm.credentials.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"d-inline-block w-100"},[_c('b-button',{staticClass:"mb-1",attrs:{"type":"submit","variant":"success","block":"","disabled":_vm.isBusy}},[_vm._v(" Sign in ")]),_c('b-button',{attrs:{"type":"button","variant":"secondary","block":"","disabled":_vm.isBusy},on:{"click":function($event){return _vm.useAuthProvider('oauth2', _vm.Oauth2)}}},[_vm._v(" Sign in with Aljay SSO ")])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }